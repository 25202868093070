import React from 'react'

export default ({ children, className }) => (
  <div id="content" className="site-content">
    <section id="primary" className="content-area">
      <main id="main" className="site-main">
        {children}
      </main>
    </section>
  </div>
)
