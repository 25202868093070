import React from 'react'
import { Link } from 'gatsby'

export default () => (
  <footer id="colophon" className="site-footer">
		<div className="site-info">
			<Link className="site-name" to="/" rel="home">Twentynineteen</Link>,{' '}
			<a href="https://gatsbyjs.org/" className="imprint">
			  Proudly powered by <del>WordPress</del> Gatsby.
      </a>
      <Link className="privacy-policy-link" to="/privacy-policy/">Privacy Policy</Link>
		</div>
	</footer>
)
