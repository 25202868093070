import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

const URL = process.env.GATSBY_WP_URL

const NavLink = ({ url, label }) => {
  if (url.startsWith(URL)) {
    const href = url.replace(URL, '')

    return (
      <Link to={href}>{label}</Link>
    )
  } else {
    return <a href={url}>{label}</a>
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        wordpress {
          generalSettings {
            title
            description
          }
          nav: menus(where: { location: MENU_1 }) {
            edges {
              node {
                name
                id
                menuItems {
                  edges {
                    node {
                      id
                      title
                      menuItemId
                      label
                      childItems {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header id="masthead" className="site-header">
        <div className="site-branding-container">
          <div className="site-branding">
            <h1 className="site-title">
              <Link to='/'>{data.wordpress.generalSettings.title}</Link>
            </h1>
            <p className="site-description">
              {data.wordpress.generalSettings.description}
            </p>

            <nav id="site-navigation" className="main-navigation" aria-label="Top Menu">
              <div className="menu-primary-menu-container">
                {data.wordpress.nav.edges.map(({ node }) => (
                  <ul key={node.id} id="menu-primary-menu" className="main-menu" tabIndex="0">
                    {node.menuItems.edges.map(({ node }) => (
                      <li key={node.id}>
                        <NavLink {...node} />
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </nav>

          </div>
        </div>
      </header>
    )}
  />
)
