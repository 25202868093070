import React from 'react'

// import SEO from 'components/SEO'
import Header from 'components/Header'
import Main from 'components/Main'
import Footer from 'components/Footer'

import '../../style.css'
import '../../blocks.css'

const Layout = ({ pageTitle, pageDescription, location, children, type }) => (
  <div className={type}>
    {/* <SEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pathname={location.pathname}
    /> */}
    <Header />
    <Main>{children}</Main>
    <Footer />
  </div>
)

export default Layout
